:root{
    --color-0: #eaeaea;
    --color-1: #ff6600;
    --color-2: #eec900;
    --color-3: #0000ff;
    --color-4: #cc00ff;
    --color-5: #00ff00;
    --color-6: #66ccff;
    --color-7: #ff0000;
}
*{
    /*border: 1px solid red;*/
    padding:0;
    margin:0;
    box-sizing: border-box;
    
}
.color-0 {
    background-color: var(--color-0);
}
  
.color-1 {
    background-color: var(--color-1);
}
  
.color-2 {
background-color: var(--color-2);
}

.color-3 {
    background-color: var(--color-3);
}

.color-4 {
    background-color: var(--color-4);
}

.color-5 {
    background-color: var(--color-5);
}

.color-6 {
    background-color: var(--color-6);
}

.color-7 {
    background-color: var(--color-7);
}
.title{
    text-align: center;
    font-size:100px;
}
body{
    background-color: blanchedalmond;
}
.container{
    display:flex;
    justify-content: space-around;
    align-items: center;
    height:100vh;
    padding-right:10vw;
    padding-left:10vw;
    .side-area{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height:75vh;
        z-index:2;
        .scoreboard{
            height:15vh;
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width:12vw;
            gap:1.5vh;
            background-color: var(--color-0);
            box-shadow:0.5vh 0.5vh black;
            color:black;
            font-size:2vh;
        }
        .meta{
            width:10vw;
            height:6vh;
            font-size: 3vh;
            background-color: var(--color-0);
            color:black;
            border-radius:25px;
            border:0.1vh solid var(--color-0);
            box-shadow:0.5vh 0.5vh black;
        }
        .meta:hover{
            background-color: black;
            color:white;
            border-radius:25px;
            border:0.1vh solid black;
            box-shadow:0.5vh 0.5vh black;
        }
        
    }
    .board-container{
        display: inline-grid;
        grid-template-columns: repeat(10, 2fr);
        grid-template-rows: repeat(20, 2fr);
        border: 0.1vh solid black;
        width:20vw;
        height:75vh;
        box-shadow:0.5vh 0.5vh black;
    }
    
}
.cell{
    border: solid;
}
.next-block{
    display:inline-grid;
    grid-template-columns: repeat(4, 2fr);
    grid-template-rows: repeat(4, 2fr);
    width:15vw;
    height:30vh;
    box-shadow:0.5vh 0.5vh black;
}
.start-container{
    display:flex;
    align-items: center;
    justify-content: center;
    min-height:100vh;
    .start-menu{
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        height:60vh;
        align-items: center;
        width:25vw;
        background-color: var(--color-0);
        box-shadow: 1vh 1vh black;
        .title{
            font-size:15vh;
        }
        .play-button{
            width:20vw;
            height:10vh;
            font-size: 6vh;
            background-color: var(--color-5);
            color:white;
            border-radius:25px;
            border:1px solid var(--color-5);
        }
        .play-button:hover{
            background-color: white;
            color:var(--color-5);
            cursor:pointer;
        }
    }
}

.popup{
    position:absolute;
    left:50%;
    top:50%;
    width:100vw;
    height:100vh;
    transform:translate(-50%,-50%);
    background-color: rgb(240,248,255,0.5);
    
}
.popup h1{
    position:absolute;
    top:50%;
    left:0;
    right:0;
    font-size:6vh;
    transform:translateY(-50%);
    text-align: center;
}
.popup.hidden {
    display: none;
}
.dialogue-container{
    padding-top:2%;
    padding-bottom:15%;
    display:flex;
    flex-direction: column;
    gap:1vh;
    height: 25%;
    width:25%;
    background-color: var(--color-0);
    box-shadow: 1vh 1vh black;
    text-align: center;
    font-size:2vh;
}
.join-container{
    padding-top:2%;
    padding-bottom:15%;
    display:flex;
    flex-direction: column;
    gap:5vh;
    height: 25%;
    width:25%;
    background-color: var(--color-0);
    box-shadow: 1vh 1vh black;
    text-align: center;
    font-size:2vh;
}
.gameroom-container{
    display:flex;
    flex-direction: column;
    height: 25%;
    width:25%;
    background-color: var(--color-0);
    box-shadow: 1vh 1vh black;
    font-size:2vh;
    justify-content: space-evenly;
    align-items: center;
}
.inactive{
    scale:0;
    position:absolute;
}
.start-game{
    width:10vw;
    height:5vh;
    font-size: 2vh;
    background-color: var(--color-5);
    color:white;
    border-radius:2vh;
    border:1px solid var(--color-5);
}
.start-game:hover{
    background-color: white;
    color:var(--color-5);
    cursor:pointer;
}
.username-form,.id-form{
    display:flex;
    flex-direction: column;
    width:100%;
    align-items: center;
    height:8vh;
}

.username-input,.id-input{
    width:50%;
    height:40%;
}
.username-button,.id-button{
    width:6vw;
    height:4vh;
    font-size: 2vh;
    background-color: var(--color-5);
    color:white;
    border-radius:2vh;
    border:1px solid var(--color-5);
}
.username-button:hover{
    background-color: white;
    color:var(--color-5);
    cursor:pointer;
}
.id-button:hover{
    background-color: white;
    color:var(--color-5);
    cursor:pointer;
}
.side-area-multi{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height:50vh;
    margin-bottom:15vh;
    .scoreboard{
        height:15vh;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:12vw;
        gap:1.5vh;
        background-color: var(--color-0);
        box-shadow:0.5vh 0.5vh black;
        color:black;
        font-size:2vh;
    }
}
.buttons-container-multi{
    position:absolute;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    top:72.5vh;
    height:15vh;
    .meta{
        width:10vw;
        height:6vh;
        font-size: 3vh;
        background-color: var(--color-0);
        color:black;
        border-radius:25px;
        border:0.1vh solid var(--color-0);
        box-shadow:0.5vh 0.5vh black;
    }
    .meta:hover{
        background-color: black;
        color:white;
        border-radius:25px;
        border:0.1vh solid black;
        box-shadow:0.5vh 0.5vh black;
    }
}
.wait-screen{
    position:absolute;
    left:50%;
    top:50%;
    width:100vw;
    height:100vh;
    transform:translate(-50%,-50%);
    background-color: rgb(240,248,255);
    text-align: center;
    z-index:1
}
.wait-screen h2{
    font-size:3vh;
    top:100%;
    left:0;
    right:0;
    transform:translateY(1000%);
    text-align: center;
}
.wait-screen.hidden {
    display: none;
    z-index:0;
}